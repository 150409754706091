declare const __PEACHPAY_GIT_VERSION__: string;
declare const __PEACHPAY_GIT_COMMITHASH__: string;
declare const __PEACHPAY_GIT_BRANCH__: string;
declare const __PEACHPAY_GIT_LASTCOMMITDATETIME__: string;
declare const __PLUGIN_VERSION__: string;

const VERSION = __PEACHPAY_GIT_VERSION__;
const COMMITHASH = __PEACHPAY_GIT_COMMITHASH__;
const BRANCH = __PEACHPAY_GIT_BRANCH__;
const LASTCOMMITDATETIME = __PEACHPAY_GIT_LASTCOMMITDATETIME__;

const PLUGIN_VERSION = __PLUGIN_VERSION__;

export {
	VERSION,
	COMMITHASH,
	BRANCH,
	LASTCOMMITDATETIME,
	PLUGIN_VERSION,
};
