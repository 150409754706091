/**
 * Sentry Types. These may not be accurate but we just need typescript happy
 */
interface SentryScope {
	setFingerprint(fingerprint: any[]): void;

	// Debug, error,or other...
	setLevel(level: string): void;
	setExtra(key: string, value: string): void;
}

interface SentryHub {
	onLoad(callback: () => void): void;
	init(settings: any): void;
	withScope(cb: (scope: SentryScope) => void): void;
	captureException(ex: Error): void;
	captureMessage(message: string, level?: string): void;
	getCurrentHub(): SentryHub;
}

declare const Sentry: SentryHub;

function initSentry(release: string, dsn: string) {
	const isStagingSite = /^(woo|qa|demo|ui-test|theme\d*)\.peachpay\.app/i;
	const isLocalSite = /^(store\.local|localhost|127\.0\.0\.1)/i;

	const isDevelopmentMode = isStagingSite.test(location.hostname) || isLocalSite.test(location.hostname);

	try {
		Sentry.onLoad(() => {
			Sentry.init({
				dsn,
				environment: isDevelopmentMode ? 'development' : 'production',
				release,
				beforeSend(event: unknown, hint: {originalException: unknown; syntheticException: unknown}) {
					// All of the PeachPay errors from the PeachPay SDK are atleast an instance of `Error`. We will
					// exclude everything else which is probably not our exceptions anyways.
					if (!(hint.originalException instanceof Error)) {
						return null;
					}

					// We only want exceptions that come from the Plugin folder "peachpay-for-woocommerce" or the page slug "express-checkout"
					if (!hint.originalException.stack?.includes('peachpay-for-woocommerce') && !hint.originalException.stack?.includes('express-checkout')) {
						return null;
					}

					return event;
				},
				// Ignore errors produced by Chrome, Firefox, and Safari upon
				// navigating away from a page that has a fetch request in progress.
				// See https://forum.sentry.io/t/typeerror-failed-to-fetch-reported-over-and-overe/8447/2
				ignoreErrors: [
					'TypeError: Failed to fetch',
					'TypeError: NetworkError when attempting to fetch resource.',
					'TypeError: cancelled',
					'TypeError: cancelado',
					'TypeError: Abgebrochen',
					'TypeError: annulé',
					'Window navigated away',
					'annullato',
					'Load failed',
				],
			});
		});
	} catch {
	// Sentry is not present. Do not make things worse!
	}
}

/**
 * Used to capture a exception with sentry
 *
 * @param error The error/exception to report
 * @param extra Details to include with the sentry report
 * @param fingerprint Fingerprint to identify a sequence of events?
 */
function captureSentryException(error: Error, extra?: Record<string, any> | null, fingerprint?: any[] | null) {
	try {
		Sentry.withScope(scope => {
			if (extra) {
				// Attempt extras
				try {
					Object.entries(extra).forEach(([key, value]) => {
						scope.setExtra(key, value);
					});
				} catch {
					// Do no harm.
				}
			}

			if (fingerprint) {
				// Attempt Fingerprint
				try {
					scope.setFingerprint(fingerprint);
				} catch {
					// Do no harm.
				}
			}

			// Capture exception with any above set extras and/or fingerprint
			Sentry.captureException(error);
		});
	} catch {
		// Sentry is not present. Don't make things worse.
	}
}

export {
	initSentry,
	captureSentryException,
};
